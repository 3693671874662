import "../sideBar.css";
const OpenedSideBarLogo = (props) => {
  const { sideBarState, handleCloseSideBar } = props;
  return (
    <img
      className={sideBarState ? "ymsHorizontalHover" : "ymsHorizontal"}
      src={process.env.REACT_APP_STATIC_IMG_BAR +"app-bar-images/draypowerYMS.svg"}
      alt="Supra Logo"
      onClick={() => handleCloseSideBar()}
      id="YMSopenedSideBarLogo"
    />
  );
};
export default OpenedSideBarLogo;
